import { useDevAuthToken } from '@retail/dev-auth';
import { Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import './i18n';
import './styles/definitions.less';

import { RootProvider } from './providers/RootProvider';
import { ROUTES } from './routes';

export default function Root() {
  useDevAuthToken();

  return (
    <div data-qa="clm-platform-config-root">
      <Suspense fallback="">
        <RootProvider>
          <BrowserRouter>
            <Routes>
              {ROUTES.map(({ path, element }) => (
                <Route key={path} path={path} element={element} />
              ))}
            </Routes>
          </BrowserRouter>
        </RootProvider>
      </Suspense>
    </div>
  );
}
