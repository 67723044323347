import { PATHS } from '@retail/backoffice-clm-urls';
import { lazy } from 'react';
import { PathRouteProps } from 'react-router-dom';

const SourcePlatforms = lazy(() => import('./pages/SourcePlatforms'));

export const ROUTES: PathRouteProps[] = [
  {
    path: `${PATHS.OPPS}/source-platforms`,
    element: <SourcePlatforms />
  }
];
