import {
  createContext,
  FunctionComponent,
  PropsWithChildren,
  useContext
} from 'react';

import {
  GetCurrentUserInfoQuery,
  useGetCurrentUserInfoQuery
} from '~/apollo/gql-types';

type CurrentUserInfo = GetCurrentUserInfoQuery['getCurrentUserInfo'];
type UserInfo = CurrentUserInfo['userInfo'];
type UserFlags = UserInfo['flags'];

const UserContext = createContext({} as CurrentUserInfo);

export function UserProvider({ children }: PropsWithChildren<unknown>) {
  const { data } = useGetCurrentUserInfoQuery();
  const userInfo = data?.getCurrentUserInfo;
  const enabled = Boolean(userInfo?.userInfo?.enabled);

  return enabled ? (
    <UserContext.Provider value={userInfo}>{children}</UserContext.Provider>
  ) : null;
}

export function useUserContex() {
  return useContext(UserContext);
}

export function useFlags() {
  return useUserContex()?.userInfo?.flags;
}

export function withFlag<T extends object>(key: keyof UserFlags) {
  return (As: FunctionComponent<T>) => (props: T) => {
    const roles = useFlags();

    return roles?.[key] ? <As {...props} /> : <></>;
  };
}
