import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export interface Scalars {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  LocalDate: any;
  LocalDateTime: any;
  Long: number;
  Upload: any;
}

export interface AcAvailabilitySchedule {
  __typename?: 'ACAvailabilitySchedule';
  availabilitySchedules: Array<AvailabilitySchedule>;
  branchId: Scalars['ID'];
}

export interface AddressInput {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  province?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['ID']>;
  zip?: InputMaybe<Scalars['String']>;
}

export interface AgentsBackedByUserPayload {
  __typename?: 'AgentsBackedByUserPayload';
  agentsBacked: Array<Maybe<User>>;
  userId: Scalars['ID'];
}

export interface Appointment {
  __typename?: 'Appointment';
  comment?: Maybe<Scalars['String']>;
  customerLead: CustomerLead;
  id: Scalars['ID'];
  meetingOccurred?: Maybe<Scalars['Boolean']>;
  opportunities?: Maybe<Array<Maybe<OpportunityWithProduct>>>;
  /** @deprecated Field is deprecated! Please use 'opportunities' */
  opportunity?: Maybe<OpportunityWithProduct>;
  slot: Scalars['Int'];
  status: Scalars['String'];
}

export interface ArchiveCustomerLeadInput {
  archivingReason: Scalars['String'];
  archivingSubReason?: InputMaybe<Scalars['String']>;
  customerLeadUuid: Scalars['ID'];
}

export interface ArchiveCustomerLeadPayload {
  __typename?: 'ArchiveCustomerLeadPayload';
  customerLead: CustomerLead;
}

export interface AssigmentConfig {
  __typename?: 'AssigmentConfig';
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  labelsEnabled?: Maybe<Scalars['Boolean']>;
  lsmEnabled?: Maybe<Scalars['Boolean']>;
  market?: Maybe<Scalars['String']>;
}

export interface AssigmentConfigInput {
  labels?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  labelsEnabled?: InputMaybe<Scalars['Boolean']>;
  lsmEnabled?: InputMaybe<Scalars['Boolean']>;
  market?: InputMaybe<Scalars['String']>;
}

export interface AssigmentConfigs {
  __typename?: 'AssigmentConfigs';
  defaultLabels?: Maybe<Array<Maybe<Scalars['String']>>>;
  markets?: Maybe<Array<Maybe<AssigmentConfig>>>;
}

export interface AssigmentConfigsInput {
  markets?: InputMaybe<Array<InputMaybe<AssigmentConfigInput>>>;
}

export interface AssignCustomerLeadInput {
  customerLeadUuid: Scalars['ID'];
  userUuid?: InputMaybe<Scalars['ID']>;
}

export interface AssignCustomerLeadPayload {
  __typename?: 'AssignCustomerLeadPayload';
  customerLead: CustomerLead;
}

export interface AssignCustomerLeadToMyselfInput {
  customerLeadUuid: Scalars['ID'];
}

export interface AssignCustomerLeadToMyselfPayload {
  __typename?: 'AssignCustomerLeadToMyselfPayload';
  customerLead: CustomerLead;
}

export interface AvailabilitySchedule {
  __typename?: 'AvailabilitySchedule';
  dayOfWeek: Scalars['Int'];
  userSchedules: Array<UserSchedule>;
  week: Scalars['Int'];
  year: Scalars['Int'];
}

export enum CallReason {
  CancellationReturn = 'CANCELLATION_RETURN',
  Claims = 'CLAIMS',
  Delivery = 'DELIVERY',
  Financing = 'FINANCING',
  Other = 'OTHER',
  RegistrationVehicleDocuments = 'REGISTRATION_VEHICLE_DOCUMENTS',
  Sales = 'SALES',
  VerificationCall = 'VERIFICATION_CALL'
}

export interface CallSettings {
  __typename?: 'CallSettings';
  isSales?: Maybe<Scalars['Boolean']>;
  sourcePlatform?: Maybe<Scalars['String']>;
}

export interface CallSettingsInput {
  phoneNumber: Scalars['String'];
  queue: Scalars['String'];
  teams: Array<InputMaybe<Scalars['String']>>;
}

export interface CarLead {
  __typename?: 'CarLead';
  brand?: Maybe<Scalars['String']>;
  fuelType?: Maybe<Scalars['String']>;
  gearType?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  mileage?: Maybe<Mileage>;
  model?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Int']>;
  registration?: Maybe<Scalars['String']>;
  stockNumber?: Maybe<Scalars['String']>;
}

export interface CarProduct extends Product {
  __typename?: 'CarProduct';
  adUrl?: Maybe<Scalars['String']>;
  blockers?: Maybe<Array<Maybe<PublishingBlocker>>>;
  comingSoon?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Date'];
  createdBy?: Maybe<User>;
  id?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['Date']>;
  lastModifiedBy?: Maybe<User>;
  meta?: Maybe<VehicleMeta>;
  /** @deprecated Field is deprecated! Please use 'opportunities' with proper input filter */
  opportunities: Array<Opportunity>;
  /** @deprecated Field is deprecated! Please use 'opportunities' with proper input filter */
  opportunitiesCount: Scalars['Int'];
  price?: Maybe<Price>;
  secondaryWheels?: Maybe<SecondaryWheels>;
  showroomInfo?: Maybe<ShowroomInfo>;
  title?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['ID']>;
  vehicle?: Maybe<Vehicle>;
  vin?: Maybe<Scalars['String']>;
  warrantyOptions?: Maybe<Array<Maybe<WarrantyOption>>>;
}

export interface ChangeOpportunitySourcePlatformInput {
  opportunityUuid: Scalars['ID'];
  sourceAccountUuid?: InputMaybe<Scalars['ID']>;
  sourcePlatform: Scalars['String'];
}

export interface ChangeOpportunitySourcePlatformPayload {
  __typename?: 'ChangeOpportunitySourcePlatformPayload';
  opportunity: Opportunity;
}

export interface ChangeOpportunityStatusInput {
  opportunityUuid: Scalars['ID'];
  status?: InputMaybe<Scalars['String']>;
}

export interface ChangeOpportunityStatusPayload {
  __typename?: 'ChangeOpportunityStatusPayload';
  opportunity: Opportunity;
}

export interface CloseOpportunityInput {
  closingReason: OpportunityClosingReason;
  closingSubReason?: InputMaybe<Scalars['String']>;
  opportunityUuid: Scalars['ID'];
}

export interface CloseOpportunityPayload {
  __typename?: 'CloseOpportunityPayload';
  opportunity: Opportunity;
}

export interface ClosingReason {
  __typename?: 'ClosingReason';
  reason?: Maybe<Reason>;
  showComment?: Maybe<Scalars['Boolean']>;
  subReasons?: Maybe<Array<Maybe<Reason>>>;
}

export enum CommunicationChannel {
  Email = 'EMAIL',
  Other = 'OTHER',
  Phone = 'PHONE'
}

export interface CompiledEmailTemplate {
  __typename?: 'CompiledEmailTemplate';
  availableAttachments: Array<Maybe<EmailTemplateAttachment>>;
  body: Scalars['String'];
  id: Scalars['ID'];
  locale?: Maybe<Scalars['String']>;
  market: Scalars['String'];
  name: Scalars['String'];
  subject: Scalars['String'];
}

export interface CompleteTaskInput {
  uuid: Scalars['ID'];
}

export interface ContactAddress {
  __typename?: 'ContactAddress';
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  createdBy?: Maybe<User>;
  lastModifiedAt?: Maybe<Scalars['Date']>;
  lastModifiedBy?: Maybe<User>;
  province?: Maybe<Scalars['String']>;
  status: ContactStatus;
  streetAddress?: Maybe<Scalars['String']>;
  streetAddress2?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
  zip?: Maybe<Scalars['String']>;
}

export interface ContactEmail {
  __typename?: 'ContactEmail';
  createdAt: Scalars['Date'];
  createdBy?: Maybe<User>;
  lastModifiedAt?: Maybe<Scalars['Date']>;
  lastModifiedBy?: Maybe<User>;
  normalisedValue?: Maybe<Scalars['String']>;
  originalValue?: Maybe<Scalars['String']>;
  status: ContactStatus;
  uuid: Scalars['ID'];
}

export interface ContactPhone {
  __typename?: 'ContactPhone';
  createdAt: Scalars['Date'];
  createdBy?: Maybe<User>;
  lastModifiedAt?: Maybe<Scalars['Date']>;
  lastModifiedBy?: Maybe<User>;
  normalisedValue?: Maybe<Scalars['String']>;
  originalValue?: Maybe<Scalars['String']>;
  status: ContactStatus;
  uuid: Scalars['ID'];
}

export enum ContactStatus {
  Active = 'ACTIVE',
  Deleted = 'DELETED'
}

export interface CreateCustomerLeadInput {
  customerLead: CustomerLeadInput;
  opportunity?: InputMaybe<OpportunityInput>;
}

export interface CreateCustomerLeadPayload {
  __typename?: 'CreateCustomerLeadPayload';
  customerLead: CustomerLead;
  opportunity?: Maybe<Opportunity>;
}

export interface CreateCustomerLeadWithInteractionInput {
  customerLead: CustomerLeadInput;
  interaction: InteractionInput;
  opportunity?: InputMaybe<OpportunityInput>;
}

export interface CreateDocumentUploadHeaderInput {
  mimeType: Scalars['String'];
  originalFilename: Scalars['String'];
}

export interface CreateDocumentUploadHeaderPayload {
  __typename?: 'CreateDocumentUploadHeaderPayload';
  documentUpload: DocumentUpload;
}

export interface CreateExternalPhoneNumbersInput {
  phones?: InputMaybe<Array<Scalars['String']>>;
  sourceAccountId?: InputMaybe<Scalars['ID']>;
  sourcePlatformId: Scalars['ID'];
}

export interface CreateInteractionInput {
  customerLeadUuid: Scalars['ID'];
  dateTime?: InputMaybe<Scalars['Date']>;
  interactionChannel: Scalars['String'];
  notes: Scalars['String'];
  opportunityUuids?: InputMaybe<Array<Scalars['ID']>>;
  sourceAccountId?: InputMaybe<Scalars['ID']>;
}

export interface CreateInteractionPayload {
  __typename?: 'CreateInteractionPayload';
  interaction: Interaction;
}

export interface CreateOpportunityInput {
  opportunity: OpportunityInput;
}

export interface CreateOpportunityPayload {
  __typename?: 'CreateOpportunityPayload';
  opportunity: Opportunity;
}

export interface CreateOpportunityWithInteractionInput {
  interaction: InteractionInput;
  opportunity: OpportunityInput;
}

export interface CreateSourceAccountInput {
  emails?: InputMaybe<Array<Scalars['String']>>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId2?: InputMaybe<Scalars['String']>;
  isActive?: InputMaybe<Scalars['Boolean']>;
  isBranch?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phones?: InputMaybe<Array<Scalars['String']>>;
  sourcePlatformId: Scalars['ID'];
  wkdaBranchId?: InputMaybe<Scalars['Long']>;
}

export interface CreateSourcePlatformInput {
  isActive?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phones?: InputMaybe<Array<Scalars['String']>>;
}

export interface CreateTaskInput {
  customerLeadUuid: Scalars['ID'];
  targetTimestamp: Scalars['Date'];
  type: Scalars['String'];
}

export interface CreateTestDriveAppointmentInput {
  agentId: Scalars['ID'];
  branchId: Scalars['ID'];
  customerLeadId: Scalars['ID'];
  from: Scalars['LocalDateTime'];
  opportunityUuids?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  till: Scalars['LocalDateTime'];
}

export interface CurrentUserInfo {
  __typename?: 'CurrentUserInfo';
  platforms: Array<Maybe<Scalars['String']>>;
  userInfo?: Maybe<UserInfo>;
}

export interface CustomerLead {
  __typename?: 'CustomerLead';
  /** All the Opportunities (including closed ones) for the Customer Lead */
  allOpportunities: Array<Opportunity>;
  /**
   * The total number of Opportunities (including closed ones) for the Customer Lead
   * @deprecated Please use 'opportunityCounts.allOpportunitiesCount' instead
   */
  allOpportunitiesCount: Scalars['Int'];
  archivedAt?: Maybe<Scalars['Date']>;
  archivingReason?: Maybe<Scalars['String']>;
  archivingSubReason?: Maybe<Scalars['String']>;
  archivingUser?: Maybe<User>;
  assignee?: Maybe<User>;
  companyName?: Maybe<Scalars['String']>;
  contactAddress?: Maybe<ContactAddress>;
  contactEmails: Array<ContactEmail>;
  contactPhones: Array<ContactPhone>;
  createdAt: Scalars['Date'];
  createdBy?: Maybe<User>;
  dueDateAt?: Maybe<Scalars['Date']>;
  firstContactDate?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  /** Unique Identifier for Customer Lead */
  id: Scalars['String'];
  /** All the interactions related to this customer lead */
  interactions: Array<Interaction>;
  interactionsCount: Scalars['Int'];
  lastModifiedAt?: Maybe<Scalars['Date']>;
  lastModifiedBy?: Maybe<User>;
  lastName?: Maybe<Scalars['String']>;
  meta?: Maybe<CustomerLeadMeta>;
  notes?: Maybe<Scalars['String']>;
  /** The number of open/in progress/on hold Opportunities for the Customer Lead */
  openOpportunities: Array<Opportunity>;
  /**
   * Open/in progress/on hold Opportunities for the Customer Lead
   * @deprecated Please use 'opportunityCounts.openOpportunitiesCount' instead
   */
  openOpportunitiesCount: Scalars['Int'];
  opportunityCounts?: Maybe<CustomerLeadOpportunityCounts>;
  preferredCommunicationChannel?: Maybe<CommunicationChannel>;
  preferredLocale?: Maybe<Scalars['String']>;
  primaryEmail?: Maybe<ContactEmail>;
  primaryPhone?: Maybe<ContactPhone>;
  salutation?: Maybe<Scalars['String']>;
  score?: Maybe<CustomerLeadScore>;
  status?: Maybe<Scalars['String']>;
  tradeIns?: Maybe<Array<Maybe<TradeIn>>>;
  type?: Maybe<CustomerLeadType>;
  uuid: Scalars['ID'];
}


export interface CustomerLeadInteractionsArgs {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface CustomerLeadDto {
  __typename?: 'CustomerLeadDTO';
  archivedAt?: Maybe<Scalars['Date']>;
  archivedBy?: Maybe<Scalars['ID']>;
  archivingReason?: Maybe<Scalars['String']>;
  archivingSubReason?: Maybe<Scalars['String']>;
  assignedTo?: Maybe<Scalars['ID']>;
  companyName?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<Scalars['ID']>;
  customerLeadId?: Maybe<Scalars['String']>;
  firstContactDate?: Maybe<Scalars['Date']>;
  firstName?: Maybe<Scalars['String']>;
  fullName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastUpdatedAt?: Maybe<Scalars['Date']>;
  lastUpdatedBy?: Maybe<Scalars['ID']>;
  notes?: Maybe<Scalars['String']>;
  platform?: Maybe<Scalars['String']>;
  preferredCommunicationChannel?: Maybe<CommunicationChannel>;
  preferredLocale?: Maybe<Scalars['String']>;
  primaryAddressUuid?: Maybe<Scalars['ID']>;
  primaryEmailUuid?: Maybe<Scalars['ID']>;
  primaryPhoneUuid?: Maybe<Scalars['ID']>;
  salutation?: Maybe<Salutation>;
  sourcePlatform?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<CustomerLeadType>;
  userName?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['ID']>;
}

export interface CustomerLeadInput {
  address?: InputMaybe<AddressInput>;
  companyName?: InputMaybe<Scalars['String']>;
  customerLeadUuid?: InputMaybe<Scalars['ID']>;
  email?: InputMaybe<EmailInput>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phoneNumbers?: InputMaybe<Array<PhoneInput>>;
  preferredCommunicationChannel?: InputMaybe<CommunicationChannel>;
  salutation?: InputMaybe<Salutation>;
  type: CustomerLeadType;
}

export interface CustomerLeadMeta {
  __typename?: 'CustomerLeadMeta';
  availableEmailLanguages: Array<Scalars['String']>;
  customerLeadAssignedToOtherUser: Scalars['Boolean'];
}

export interface CustomerLeadOpportunityCounts {
  __typename?: 'CustomerLeadOpportunityCounts';
  allOpportunitiesCount?: Maybe<Scalars['Int']>;
  openOpportunitiesCount?: Maybe<Scalars['Int']>;
}

export interface CustomerLeadScore {
  __typename?: 'CustomerLeadScore';
  createdAt?: Maybe<Scalars['Date']>;
  lastModifiedAt?: Maybe<Scalars['Date']>;
  outdated?: Maybe<Scalars['Boolean']>;
  probability?: Maybe<Scalars['Float']>;
  score?: Maybe<CustomerLeadScoreValue>;
  uuid: Scalars['ID'];
}

export enum CustomerLeadScoreValue {
  Failed = 'FAILED',
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM',
  Reassigned = 'REASSIGNED',
  Undefined = 'UNDEFINED'
}

export enum CustomerLeadType {
  Company = 'COMPANY',
  Dealer = 'DEALER',
  Private = 'PRIVATE',
  Unknown = 'UNKNOWN'
}

export interface DocumentHeader {
  __typename?: 'DocumentHeader';
  key: Scalars['String'];
  value: Scalars['String'];
}

export interface DocumentUpload {
  __typename?: 'DocumentUpload';
  cdn: Scalars['String'];
  filename: Scalars['String'];
  formData?: Maybe<Array<DocumentHeader>>;
  host: Scalars['String'];
  preSignedUrl?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
}

export interface EmailAddress {
  __typename?: 'EmailAddress';
  email?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
}

export interface EmailAddressInput {
  email: Scalars['String'];
}

export interface EmailInput {
  email?: InputMaybe<Scalars['String']>;
  preferred: Scalars['Boolean'];
  uuid?: InputMaybe<Scalars['ID']>;
}

export interface EmailTemplate {
  __typename?: 'EmailTemplate';
  body: Scalars['String'];
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  lastUpdatedAt?: Maybe<Scalars['Date']>;
  lastUpdatedBy?: Maybe<User>;
  locale?: Maybe<Scalars['String']>;
  market: Scalars['String'];
  name: Scalars['String'];
  status?: Maybe<Scalars['String']>;
  subject: Scalars['String'];
  userFriendlyId: Scalars['Int'];
}

export interface EmailTemplateAttachment {
  __typename?: 'EmailTemplateAttachment';
  label: Scalars['String'];
  link: Scalars['String'];
  name: Scalars['String'];
  type: EmailTemplateAttachmentType;
}

export enum EmailTemplateAttachmentType {
  Add = 'ADD'
}

export interface EmailTemplateInput {
  body?: InputMaybe<Scalars['String']>;
  locale?: InputMaybe<Scalars['String']>;
  market: Scalars['String'];
  name: Scalars['String'];
  status: Scalars['String'];
  subject?: InputMaybe<Scalars['String']>;
}

export interface EmailTemplatePlaceholdersGroup {
  __typename?: 'EmailTemplatePlaceholdersGroup';
  group: Scalars['String'];
  placeholders: Array<Maybe<Scalars['String']>>;
}

export enum EmailTemplatesSortingField {
  LastUpdatedAt = 'LAST_UPDATED_AT',
  Name = 'NAME'
}

export interface ExternalPhoneNumberPayload {
  __typename?: 'ExternalPhoneNumberPayload';
  id?: Maybe<Scalars['ID']>;
  number?: Maybe<Scalars['String']>;
  sourceAccountId?: Maybe<Scalars['ID']>;
  sourcePlatformId?: Maybe<Scalars['ID']>;
}

export interface FacetInfo {
  __typename?: 'FacetInfo';
  buckets: Array<StatisticsBucket>;
  enabled?: Maybe<Scalars['Boolean']>;
  facetName: Scalars['String'];
}

export interface FeatureFilter {
  country?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  environment?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
}

export interface FeatureStatus {
  __typename?: 'FeatureStatus';
  name?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface FetchOpportunityInput {
  uuid: Scalars['ID'];
}

export interface FetchOpportunityPayload {
  __typename?: 'FetchOpportunityPayload';
  opportunity?: Maybe<Opportunity>;
}

export interface FetchTaskPageInfo {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}

export interface FetchUserBackupsInput {
  userId: Scalars['ID'];
  week: Scalars['Int'];
  year?: InputMaybe<Scalars['Int']>;
}

export interface FinalizeCallInput {
  comments: Scalars['String'];
  duration?: InputMaybe<Scalars['Long']>;
  interactionUuid: Scalars['ID'];
  opportunityUuids: Array<Scalars['ID']>;
}

export interface FinalizeCallPayload {
  __typename?: 'FinalizeCallPayload';
  interaction: Interaction;
}

export interface FinalizeContactCenterCallInput {
  comment: Scalars['String'];
  externalId?: InputMaybe<Scalars['String']>;
  interactionUuid?: InputMaybe<Scalars['ID']>;
  opportunityUuids: Array<Scalars['ID']>;
  reason: CallReason;
}

export interface FinalizeContactCenterCallPayload {
  __typename?: 'FinalizeContactCenterCallPayload';
  interaction: Interaction;
}

export interface GetClosingReasonsPayload {
  __typename?: 'GetClosingReasonsPayload';
  reasons?: Maybe<Array<Maybe<ClosingReason>>>;
}

export interface GetCustomerLeadAssignmentsPayload {
  __typename?: 'GetCustomerLeadAssignmentsPayload';
  leadAssignments: Array<LeadAssignment>;
}

export interface GetCustomerLeadOverviewPayload {
  __typename?: 'GetCustomerLeadOverviewPayload';
  currentUserAvailableForAssignment: Scalars['Boolean'];
  customerLead: CustomerLead;
  enableArchiving: Scalars['Boolean'];
  enablePhoneCall: Scalars['Boolean'];
  enableReopening: Scalars['Boolean'];
  merged: Scalars['Boolean'];
}

export interface GetMergedCustomerLeadsPayload {
  __typename?: 'GetMergedCustomerLeadsPayload';
  mergedAt: Scalars['Date'];
  mergedBy?: Maybe<User>;
  mergedLeads: Array<Maybe<MergedCustomerLead>>;
}

export interface InitiateCallInput {
  contactPhoneUuid: Scalars['ID'];
  customerLeadUuid: Scalars['ID'];
}

export interface InitiateCallPayload {
  __typename?: 'InitiateCallPayload';
  interaction?: Maybe<Interaction>;
}

export interface InitiateContactCenterCallPayload {
  __typename?: 'InitiateContactCenterCallPayload';
  interaction?: Maybe<Interaction>;
}

export interface Interaction {
  __typename?: 'Interaction';
  channel?: Maybe<Scalars['String']>;
  channelActionType?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  customerLead?: Maybe<CustomerLead>;
  dateTime?: Maybe<Scalars['Date']>;
  documents: Array<InteractionDocument>;
  externalId?: Maybe<Scalars['String']>;
  handledAt?: Maybe<Scalars['Date']>;
  handledBy?: Maybe<User>;
  interactionNotes?: Maybe<Array<Maybe<InteractionNote>>>;
  notes?: Maybe<Scalars['String']>;
  opportunities?: Maybe<Array<Opportunity>>;
  properties?: Maybe<Array<InteractionProperty>>;
  uuid: Scalars['ID'];
}

export interface InteractionDocument {
  __typename?: 'InteractionDocument';
  displayOrder?: Maybe<Scalars['Int']>;
  mimeType?: Maybe<Scalars['String']>;
  originalFilename?: Maybe<Scalars['String']>;
  stored?: Maybe<Scalars['Boolean']>;
  thumbnailUrl?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
}

export interface InteractionInput {
  interactionType: Scalars['String'];
  message: Scalars['String'];
}

export interface InteractionNote {
  __typename?: 'InteractionNote';
  batchId?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  id: Scalars['ID'];
  note?: Maybe<Scalars['String']>;
  type?: Maybe<InteractionNoteType>;
}

export enum InteractionNoteType {
  CallerWaitingTime = 'CALLER_WAITING_TIME',
  Comment = 'COMMENT',
  ConversationTime = 'CONVERSATION_TIME',
  Reason = 'REASON',
  Transfer = 'TRANSFER'
}

export interface InteractionProperty {
  __typename?: 'InteractionProperty';
  name: Scalars['String'];
  value?: Maybe<Scalars['String']>;
}

export enum ItemOperation {
  Create = 'CREATE',
  Delete = 'DELETE',
  NoChange = 'NO_CHANGE',
  Update = 'UPDATE'
}

export interface LeadAssignment {
  __typename?: 'LeadAssignment';
  assignedAt: Scalars['Date'];
  assignedBy?: Maybe<User>;
  assignedTo?: Maybe<User>;
  leadUuid: Scalars['ID'];
}

export interface Link {
  __typename?: 'Link';
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
}

export interface MergeCustomerLeadsInput {
  customerLead: CustomerLeadInput;
  mergingLeadFrom: Scalars['ID'];
  mergingLeadTo: Scalars['ID'];
}

export interface MergeCustomerLeadsPayload {
  __typename?: 'MergeCustomerLeadsPayload';
  customerLead: CustomerLead;
}

export interface MergeableCustomerLeadPayload {
  __typename?: 'MergeableCustomerLeadPayload';
  customerLead: CustomerLead;
  isMergeable?: Maybe<Scalars['Boolean']>;
  validationCode?: Maybe<Scalars['String']>;
}

export interface MergedCustomerLead {
  __typename?: 'MergedCustomerLead';
  id: Scalars['String'];
  uuid: Scalars['ID'];
}

export interface Mileage {
  __typename?: 'Mileage';
  distance?: Maybe<Scalars['Int']>;
  unit?: Maybe<Scalars['String']>;
}

export interface Mutation {
  __typename?: 'Mutation';
  archiveCustomerLead?: Maybe<ArchiveCustomerLeadPayload>;
  assignCustomerLead?: Maybe<AssignCustomerLeadPayload>;
  assignCustomerLeadToMyself?: Maybe<AssignCustomerLeadToMyselfPayload>;
  changeOpportunitySourcePlatform?: Maybe<ChangeOpportunitySourcePlatformPayload>;
  changeOpportunityStatus?: Maybe<ChangeOpportunityStatusPayload>;
  closeOpportunity?: Maybe<CloseOpportunityPayload>;
  completeTask?: Maybe<Task>;
  confirmTestDriveAppointment: Scalars['ID'];
  createCustomerLead?: Maybe<CreateCustomerLeadPayload>;
  createCustomerLeadWithInteraction?: Maybe<CreateCustomerLeadPayload>;
  createDocumentUploadHeader?: Maybe<CreateDocumentUploadHeaderPayload>;
  /** email */
  createEmailTemplate: EmailTemplate;
  createExternalPhoneNumbers?: Maybe<Array<Maybe<ExternalPhoneNumberPayload>>>;
  createInteraction?: Maybe<CreateInteractionPayload>;
  /** @deprecated Use 'createOpportunityWithInteraction' instead */
  createOpportunity?: Maybe<CreateOpportunityPayload>;
  createOpportunityWithInteraction?: Maybe<CreateOpportunityPayload>;
  createSourceAccount?: Maybe<SourceAccountPayload>;
  createSourcePlatform?: Maybe<SourcePlatformPayload>;
  createTask?: Maybe<Task>;
  createTestDriveAppointment?: Maybe<TestDriveAppointmentPayload>;
  createUserBackup: UserBackupPayload;
  deleteEmailTemplate: Scalars['ID'];
  deleteTestDriveAppointment: Scalars['ID'];
  deleteUserBackup: Scalars['ID'];
  finalizeCall?: Maybe<FinalizeCallPayload>;
  finalizeContactCenterCall?: Maybe<FinalizeContactCenterCallPayload>;
  initiateCall?: Maybe<InitiateCallPayload>;
  initiateContactCenterCall?: Maybe<InitiateContactCenterCallPayload>;
  markTestDriveAppointmentOccurred?: Maybe<TestDriveAppointmentPayload>;
  markTestDriveAppointmentSkipped?: Maybe<TestDriveAppointmentPayload>;
  mergeCustomerLeads?: Maybe<MergeCustomerLeadsPayload>;
  reAssignCustomerLead?: Maybe<AssignCustomerLeadPayload>;
  reAssignCustomerLeadToMyself?: Maybe<AssignCustomerLeadToMyselfPayload>;
  rejectTestDriveAppointment: Scalars['ID'];
  reopenOpportunity?: Maybe<ReopenOpportunityPayload>;
  rescheduleTestDriveAppointment?: Maybe<TestDriveAppointmentPayload>;
  sendEmptyTemplateEmail?: Maybe<SendEmptyTemplateEmailPayload>;
  sendTestEmailToAgent: Scalars['Boolean'];
  setOpportunityProduct?: Maybe<SetOpportunityProductPayload>;
  setOpportunitySourcePlatform?: Maybe<ChangeOpportunitySourcePlatformPayload>;
  setSelfAvailability?: Maybe<UserAvailabilityPeriods>;
  setUserAvailability?: Maybe<UserAvailabilityPeriods>;
  skipTask?: Maybe<Task>;
  unAssignCustomerLead?: Maybe<AssignCustomerLeadPayload>;
  updateAssigmentConfigs?: Maybe<AssigmentConfigs>;
  updateCompoundCustomerLead?: Maybe<UpdateCustomerLeadPayload>;
  /** @deprecated Use 'updateCompoundCustomerLead' instead */
  updateCustomerLead?: Maybe<UpdateCustomerLeadPayload>;
  /**
   * New aggregate mutations
   * @deprecated Use 'updateCompoundCustomerLead' instead
   */
  updateCustomerLeadAggregate?: Maybe<UpdateCustomerAggregatePayload>;
  updateCustomerLeadNotes?: Maybe<UpdateCustomerLeadNotesPayload>;
  updateEmailTemplate: EmailTemplate;
  updateOpportunityRating?: Maybe<UpdateOpportunityRatingPayload>;
  updateOpportunityRelevance?: Maybe<UpdateOpportunityRelevancePayload>;
  updateSourceAccount?: Maybe<SourceAccountPayload>;
  updateSourcePlatform?: Maybe<SourcePlatformPayload>;
  uploadDocument?: Maybe<UploadedDocumentPayload>;
}


export interface MutationArchiveCustomerLeadArgs {
  input: ArchiveCustomerLeadInput;
}


export interface MutationAssignCustomerLeadArgs {
  input: AssignCustomerLeadInput;
}


export interface MutationAssignCustomerLeadToMyselfArgs {
  input: AssignCustomerLeadToMyselfInput;
}


export interface MutationChangeOpportunitySourcePlatformArgs {
  input: ChangeOpportunitySourcePlatformInput;
}


export interface MutationChangeOpportunityStatusArgs {
  input: ChangeOpportunityStatusInput;
}


export interface MutationCloseOpportunityArgs {
  input: CloseOpportunityInput;
}


export interface MutationCompleteTaskArgs {
  input: CompleteTaskInput;
}


export interface MutationConfirmTestDriveAppointmentArgs {
  appointmentId: Scalars['ID'];
}


export interface MutationCreateCustomerLeadArgs {
  input: CreateCustomerLeadInput;
}


export interface MutationCreateCustomerLeadWithInteractionArgs {
  input: CreateCustomerLeadWithInteractionInput;
}


export interface MutationCreateDocumentUploadHeaderArgs {
  input: CreateDocumentUploadHeaderInput;
}


export interface MutationCreateEmailTemplateArgs {
  input: EmailTemplateInput;
}


export interface MutationCreateExternalPhoneNumbersArgs {
  input: CreateExternalPhoneNumbersInput;
}


export interface MutationCreateInteractionArgs {
  input: CreateInteractionInput;
}


export interface MutationCreateOpportunityArgs {
  input: CreateOpportunityInput;
}


export interface MutationCreateOpportunityWithInteractionArgs {
  input: CreateOpportunityWithInteractionInput;
}


export interface MutationCreateSourceAccountArgs {
  input: CreateSourceAccountInput;
}


export interface MutationCreateSourcePlatformArgs {
  input: CreateSourcePlatformInput;
}


export interface MutationCreateTaskArgs {
  input: CreateTaskInput;
}


export interface MutationCreateTestDriveAppointmentArgs {
  input: CreateTestDriveAppointmentInput;
}


export interface MutationCreateUserBackupArgs {
  input: UserBackupInput;
}


export interface MutationDeleteEmailTemplateArgs {
  id: Scalars['ID'];
}


export interface MutationDeleteTestDriveAppointmentArgs {
  appointmentId: Scalars['ID'];
}


export interface MutationDeleteUserBackupArgs {
  id: Scalars['ID'];
}


export interface MutationFinalizeCallArgs {
  input: FinalizeCallInput;
}


export interface MutationFinalizeContactCenterCallArgs {
  input: FinalizeContactCenterCallInput;
}


export interface MutationInitiateCallArgs {
  input: InitiateCallInput;
}


export interface MutationInitiateContactCenterCallArgs {
  input: InitiateCallInput;
}


export interface MutationMarkTestDriveAppointmentOccurredArgs {
  appointmentId: Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
}


export interface MutationMarkTestDriveAppointmentSkippedArgs {
  appointmentId: Scalars['ID'];
  comment?: InputMaybe<Scalars['String']>;
}


export interface MutationMergeCustomerLeadsArgs {
  input: MergeCustomerLeadsInput;
}


export interface MutationReAssignCustomerLeadArgs {
  input: ReAssignCustomerLeadInput;
}


export interface MutationReAssignCustomerLeadToMyselfArgs {
  input: ReAssignCustomerLeadToMyselfInput;
}


export interface MutationRejectTestDriveAppointmentArgs {
  appointmentId: Scalars['ID'];
}


export interface MutationReopenOpportunityArgs {
  input: ReopenOpportunityInput;
}


export interface MutationRescheduleTestDriveAppointmentArgs {
  input: RescheduleTestDriveAppointmentInput;
}


export interface MutationSendEmptyTemplateEmailArgs {
  input: SendEmptyTemplateEmailInput;
}


export interface MutationSendTestEmailToAgentArgs {
  input?: InputMaybe<SendTestEmailInput>;
}


export interface MutationSetOpportunityProductArgs {
  input: SetOpportunityProductInput;
}


export interface MutationSetOpportunitySourcePlatformArgs {
  input: ChangeOpportunitySourcePlatformInput;
}


export interface MutationSetSelfAvailabilityArgs {
  input: UserAvailabilityPeriodsInput;
}


export interface MutationSetUserAvailabilityArgs {
  input: UserAvailabilityPeriodsInput;
}


export interface MutationSkipTaskArgs {
  input: SkipTaskInput;
}


export interface MutationUnAssignCustomerLeadArgs {
  input: UnAssignCustomerLeadInput;
}


export interface MutationUpdateAssigmentConfigsArgs {
  input: AssigmentConfigsInput;
}


export interface MutationUpdateCompoundCustomerLeadArgs {
  input: UpdateCustomerLeadInput;
}


export interface MutationUpdateCustomerLeadArgs {
  input: UpdateCustomerLeadInput;
}


export interface MutationUpdateCustomerLeadAggregateArgs {
  input: UpdateCustomerAggregateInput;
}


export interface MutationUpdateCustomerLeadNotesArgs {
  input: UpdateCustomerLeadNotesInput;
}


export interface MutationUpdateEmailTemplateArgs {
  id: Scalars['ID'];
  input: EmailTemplateInput;
}


export interface MutationUpdateOpportunityRatingArgs {
  input: UpdateOpportunityRatingInput;
}


export interface MutationUpdateOpportunityRelevanceArgs {
  input: UpdateOpportunityRelevanceInput;
}


export interface MutationUpdateSourceAccountArgs {
  input: UpdateSourceAccountInput;
}


export interface MutationUpdateSourcePlatformArgs {
  input: UpdateSourcePlatformInput;
}


export interface MutationUploadDocumentArgs {
  input?: InputMaybe<Scalars['Upload']>;
}

export interface OpportunitiesInfo {
  __typename?: 'OpportunitiesInfo';
  opportunitiesInfo: Array<Maybe<OpportunityInfo>>;
}

export interface OpportunitiesOverviewCounts {
  __typename?: 'OpportunitiesOverviewCounts';
  allOpportunities: Scalars['Int'];
  myOpportunities: Scalars['Int'];
  unassignedOpportunities: Scalars['Int'];
}

export interface OpportunitiesStatisticsByStatus {
  __typename?: 'OpportunitiesStatisticsByStatus';
  countByLastInteractionChannel: Array<OpportunityCountByLastInteractionChannel>;
  countByStatus: Array<OpportunityCountByStatus>;
  openCount: Scalars['Int'];
}

export interface OpportunitiesWithProduct {
  __typename?: 'OpportunitiesWithProduct';
  items: Array<OpportunityWithProduct>;
  pageInfo: PageInfo;
}

export interface Opportunity {
  __typename?: 'Opportunity';
  agent?: Maybe<User>;
  availableOpportunityStatuses: Array<Scalars['String']>;
  closedBy?: Maybe<User>;
  closingDate?: Maybe<Scalars['Date']>;
  closingReason?: Maybe<OpportunityClosingReason>;
  closingSubReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  createdBy?: Maybe<User>;
  customerLead: CustomerLead;
  /** Unique Identifier for Opportunity */
  id: Scalars['String'];
  interactions: Array<Interaction>;
  interactionsCount: Scalars['Int'];
  lastInteractionChannel?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['Date']>;
  lastModifiedBy?: Maybe<User>;
  locale: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  properties?: Maybe<Array<OpportunityProperty>>;
  rating?: Maybe<OpportunityRating>;
  /**
   * Open/in progress/on hold opportunities for the same product
   * @deprecated Field is not needed.
   */
  relatedOpportunities: Array<Opportunity>;
  /**
   * Count open/in progress/on hold opportunities for the same product
   * @deprecated Please use 'Opportunity.statistics.customersWithTheSameCar' with proper input filter
   */
  relatedOpportunitiesCount: Scalars['Int'];
  relevance?: Maybe<OpportunityRelevance>;
  reopenedBy?: Maybe<User>;
  reopeningDate?: Maybe<Scalars['Date']>;
  reopeningReason?: Maybe<OpportunityReopeningReason>;
  sourceAccount?: Maybe<SourceAccount>;
  sourceChannel: OpportunityChannel;
  sourcePlatform?: Maybe<Scalars['String']>;
  statistics: OpportunityStatistics;
  status?: Maybe<Scalars['String']>;
  tradeIn?: Maybe<TradeIn>;
  uuid: Scalars['ID'];
}


export interface OpportunityInteractionsArgs {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export enum OpportunityChannel {
  AhChatbot = 'AH_CHATBOT',
  AhLivechat = 'AH_LIVECHAT',
  AhWhatsapp = 'AH_WHATSAPP',
  Branch = 'BRANCH',
  Checkout = 'CHECKOUT',
  Email = 'EMAIL',
  LiveVideoCallRequest = 'LIVE_VIDEO_CALL_REQUEST',
  Phone = 'PHONE',
  PreCheckoutFinancing = 'PRE_CHECKOUT_FINANCING',
  TestDrive = 'TEST_DRIVE'
}

export enum OpportunityClosingReason {
  BadWkdaReputation = 'BAD_WKDA_REPUTATION',
  BuyerFoundAnotherCar = 'BUYER_FOUND_ANOTHER_CAR',
  CarCharacteristics = 'CAR_CHARACTERISTICS',
  ContractWithdrawn = 'CONTRACT_WITHDRAWN',
  CustomerNotInterestedAnymore = 'CUSTOMER_NOT_INTERESTED_ANYMORE',
  DeliveryTime = 'DELIVERY_TIME',
  Duplicate = 'DUPLICATE',
  FinancingDeclined = 'FINANCING_DECLINED',
  LeadWasArchived = 'LEAD_WAS_ARCHIVED',
  LiveChatRequestWithNoAction = 'LIVE_CHAT_REQUEST_WITH_NO_ACTION',
  LocationDiscrepancy = 'LOCATION_DISCREPANCY',
  LowScoreLead = 'LOW_SCORE_LEAD',
  NotAvailableForAssignment = 'NOT_AVAILABLE_FOR_ASSIGNMENT',
  NotPlanningToBuy = 'NOT_PLANNING_TO_BUY',
  NoContactDetails = 'NO_CONTACT_DETAILS',
  NoResponse = 'NO_RESPONSE',
  OnlineConcept = 'ONLINE_CONCEPT',
  OrderWasCancelled = 'ORDER_WAS_CANCELLED',
  OtherReason = 'OTHER_REASON',
  Price = 'PRICE',
  SellAgentWasTooPushy = 'SELL_AGENT_WAS_TOO_PUSHY',
  TestDriveAvailability = 'TEST_DRIVE_AVAILABILITY',
  TheCarIsNotAvailableAnymore = 'THE_CAR_IS_NOT_AVAILABLE_ANYMORE',
  VatNotDeductible = 'VAT_NOT_DEDUCTIBLE',
  WeCannotSellToThisCustomer = 'WE_CANNOT_SELL_TO_THIS_CUSTOMER',
  WrongContactDetails = 'WRONG_CONTACT_DETAILS'
}

export interface OpportunityCountByLastInteractionChannel {
  __typename?: 'OpportunityCountByLastInteractionChannel';
  channel: Scalars['String'];
  count: Scalars['Int'];
}

export interface OpportunityCountByStatus {
  __typename?: 'OpportunityCountByStatus';
  count: Scalars['Int'];
  status: Scalars['String'];
}

export interface OpportunityInfo {
  __typename?: 'OpportunityInfo';
  opportunityId?: Maybe<Scalars['String']>;
  opportunityUuid: Scalars['ID'];
  sourceChannelShouldUpdate?: Maybe<Scalars['Boolean']>;
}

export interface OpportunityInput {
  channel?: InputMaybe<OpportunityChannel>;
  customerLeadUuid?: InputMaybe<Scalars['ID']>;
  platform?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  rating?: InputMaybe<OpportunityRating>;
  sourceAccount?: InputMaybe<SourceInput>;
  sourcePlatform?: InputMaybe<Scalars['String']>;
}

export interface OpportunityOverviewFilter {
  archived?: InputMaybe<Scalars['Boolean']>;
  archivingReasons?: InputMaybe<Array<Scalars['String']>>;
  assignee: Array<InputMaybe<Scalars['ID']>>;
  carAttached?: InputMaybe<Scalars['Boolean']>;
  email?: InputMaybe<Scalars['String']>;
  emailMissing?: InputMaybe<Scalars['Boolean']>;
  freeText?: InputMaybe<Scalars['String']>;
  interactionChannels?: InputMaybe<Array<Scalars['String']>>;
  isPreApprovedFinancingAttached?: InputMaybe<Scalars['Boolean']>;
  latestInteractionChannels?: InputMaybe<Array<Scalars['String']>>;
  leadUuid?: InputMaybe<Scalars['ID']>;
  noAgentsInteracted?: InputMaybe<Scalars['Boolean']>;
  noAgentsOutboundEmailInteraction?: InputMaybe<Scalars['Boolean']>;
  noAgentsSuccessfulOutboundCallInteraction?: InputMaybe<Scalars['Boolean']>;
  overdueInteraction?: InputMaybe<Scalars['Boolean']>;
  overdueOpened?: InputMaybe<Scalars['Boolean']>;
  phoneMissing?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  productId?: InputMaybe<Scalars['String']>;
  productUuid?: InputMaybe<Scalars['ID']>;
  rating?: InputMaybe<Array<InputMaybe<OpportunityRating>>>;
  score?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sourceAccountNames?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sourceChannel?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  sourcePlatform?: InputMaybe<Array<Scalars['String']>>;
  status: Array<Scalars['String']>;
}

export interface OpportunityOverviewStatistics {
  __typename?: 'OpportunityOverviewStatistics';
  facetsInfo: Array<FacetInfo>;
  opportunitiesCounts?: Maybe<OpportunitiesOverviewCounts>;
}

export interface OpportunityOverviewStatisticsFilter {
  assignees: Array<InputMaybe<Scalars['ID']>>;
}

export interface OpportunityProperty {
  __typename?: 'OpportunityProperty';
  key: Scalars['String'];
  value?: Maybe<Scalars['String']>;
}

export enum OpportunityRating {
  Cold = 'COLD',
  Hot = 'HOT',
  None = 'NONE',
  Warm = 'WARM'
}

export enum OpportunityRelevance {
  High = 'HIGH',
  Low = 'LOW',
  Medium = 'MEDIUM'
}

export enum OpportunityReopeningReason {
  AutomaticCarDownpricing = 'AUTOMATIC_CAR_DOWNPRICING',
  AutomaticCarNowAvailable = 'AUTOMATIC_CAR_NOW_AVAILABLE',
  AutomaticCustomerCheckout = 'AUTOMATIC_CUSTOMER_CHECKOUT',
  AutomaticCustomerInteractionInbound = 'AUTOMATIC_CUSTOMER_INTERACTION_INBOUND',
  ManualCustomerInteractionInbound = 'MANUAL_CUSTOMER_INTERACTION_INBOUND',
  ManualCustomerInteractionOutbound = 'MANUAL_CUSTOMER_INTERACTION_OUTBOUND',
  SupervisorStatusOverwrite = 'SUPERVISOR_STATUS_OVERWRITE'
}

export enum OpportunitySortOrder {
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  LastInteractionDateAsc = 'lastInteractionDate_ASC',
  LastInteractionDateDesc = 'lastInteractionDate_DESC',
  /** @deprecated Not used and not supported by OpportunityIndexedSearch */
  LastModifiedAtAsc = 'lastModifiedAt_ASC',
  /** @deprecated Not used and not supported by OpportunityIndexedSearch */
  LastModifiedAtDesc = 'lastModifiedAt_DESC',
  LastStatusChangedAtAsc = 'lastStatusChangedAt_ASC',
  LastStatusChangedAtDesc = 'lastStatusChangedAt_DESC',
  OpportunityCustomerLeadScoreAsc = 'opportunityCustomerLeadScore_ASC',
  OpportunityCustomerLeadScoreDesc = 'opportunityCustomerLeadScore_DESC'
}

export interface OpportunityStatistics {
  __typename?: 'OpportunityStatistics';
  /** @deprecated Please use 'Opportunity.statistics.relatedOpportunities' with proper input filter */
  allOpportunitiesStatistics: OpportunitiesStatisticsByStatus;
  customersWithTheSameCar: Scalars['Int'];
  /** @deprecated Please use 'Opportunity.statistics.relatedOpportunities' with proper input filter */
  myOpportunitiesStatistics: OpportunitiesStatisticsByStatus;
  relatedOpportunities: Scalars['Int'];
  /** @deprecated Please use 'Opportunity.statistics.relatedOpportunities' with proper input filter */
  unassignedOpportunitiesStatistics: OpportunitiesStatisticsByStatus;
}

export interface OpportunityWithProduct {
  __typename?: 'OpportunityWithProduct';
  agent?: Maybe<User>;
  availableOpportunityStatuses: Array<Scalars['String']>;
  closedBy?: Maybe<User>;
  closingDate?: Maybe<Scalars['Date']>;
  closingReason?: Maybe<OpportunityClosingReason>;
  closingSubReason?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  createdBy?: Maybe<User>;
  customerLead: CustomerLead;
  /** Unique Identifier for Opportunity */
  id: Scalars['String'];
  interactions: Array<Interaction>;
  interactionsCount: Scalars['Int'];
  lastInteractionChannel?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['Date']>;
  lastModifiedBy?: Maybe<User>;
  locale: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  product?: Maybe<Product>;
  properties?: Maybe<Array<OpportunityProperty>>;
  rating?: Maybe<OpportunityRating>;
  /**
   * Count open/in progress/on hold opportunities for the same product
   * @deprecated Please use 'Opportunity.statistics.customersWithTheSameCar' with proper input filter
   */
  relatedOpportunitiesCount: Scalars['Int'];
  relevance?: Maybe<OpportunityRelevance>;
  reopenedBy?: Maybe<User>;
  reopeningDate?: Maybe<Scalars['Date']>;
  reopeningReason?: Maybe<OpportunityReopeningReason>;
  sourceAccount?: Maybe<SourceAccount>;
  sourceChannel: OpportunityChannel;
  sourcePlatform?: Maybe<Scalars['String']>;
  statistics: OpportunityStatistics;
  status?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
}


export interface OpportunityWithProductInteractionsArgs {
  first?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
}

export interface OpportunityWithProductOverview {
  __typename?: 'OpportunityWithProductOverview';
  currentUserAvailableForAssignment: Scalars['Boolean'];
  opportunities: OpportunitiesWithProduct;
  opportunitiesCounts: OpportunitiesOverviewCounts;
  opportunitiesStatistics: OpportunityOverviewStatistics;
}

export interface PageInfo {
  __typename?: 'PageInfo';
  /** current page */
  page: Scalars['Int'];
  /** number of elements in one page */
  pageSize: Scalars['Int'];
  /** total number of elements retrieved */
  totalCount: Scalars['Int'];
  /** total number of page retrieved */
  totalPageCount: Scalars['Int'];
}

export interface PhoneInput {
  number?: InputMaybe<Scalars['String']>;
  operation?: InputMaybe<ItemOperation>;
  preferred: Scalars['Boolean'];
  uuid?: InputMaybe<Scalars['ID']>;
}

export interface PhoneNumber {
  __typename?: 'PhoneNumber';
  number?: Maybe<Scalars['String']>;
  preferred?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['ID'];
}

export interface PhoneNumberInput {
  phoneNumber: Scalars['String'];
  preferred: Scalars['Boolean'];
}

export interface PlatformConfig {
  __typename?: 'PlatformConfig';
  callerId?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  multilingual?: Maybe<Scalars['Boolean']>;
  replyToEmail?: Maybe<Scalars['String']>;
}

export interface PreviewEmailTemplateInput {
  body: Scalars['String'];
  opportunityId?: InputMaybe<Scalars['String']>;
  subject: Scalars['String'];
}

export interface PreviewEmailTemplateResult {
  __typename?: 'PreviewEmailTemplateResult';
  body: Scalars['String'];
  subject: Scalars['String'];
}

export interface Price {
  __typename?: 'Price';
  amountMinorUnits?: Maybe<Scalars['Long']>;
  conversionMajor?: Maybe<Scalars['Int']>;
  currency?: Maybe<Scalars['String']>;
}

export interface Product {
  adUrl?: Maybe<Scalars['String']>;
  blockers?: Maybe<Array<Maybe<PublishingBlocker>>>;
  comingSoon?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Date'];
  createdBy?: Maybe<User>;
  id?: Maybe<Scalars['String']>;
  lastModifiedAt?: Maybe<Scalars['Date']>;
  lastModifiedBy?: Maybe<User>;
  /** @deprecated Field is deprecated! Please use 'opportunities' with proper input filter */
  opportunities: Array<Opportunity>;
  /** @deprecated Field is deprecated! Please use 'opportunities' with proper input filter */
  opportunitiesCount: Scalars['Int'];
  price?: Maybe<Price>;
  secondaryWheels?: Maybe<SecondaryWheels>;
  showroomInfo?: Maybe<ShowroomInfo>;
  title?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['ID']>;
  vin?: Maybe<Scalars['String']>;
  warrantyOptions?: Maybe<Array<Maybe<WarrantyOption>>>;
}

export interface PublishingBlocker {
  __typename?: 'PublishingBlocker';
  createdAt?: Maybe<Scalars['Date']>;
  reason?: Maybe<Scalars['String']>;
  subReason?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['ID']>;
}

export interface Query {
  __typename?: 'Query';
  callSettings: CallSettings;
  compiledEmailTemplate?: Maybe<CompiledEmailTemplate>;
  emailTemplates: Array<Maybe<EmailTemplate>>;
  fetchACAvailabilitySchedule?: Maybe<AcAvailabilitySchedule>;
  fetchAgentsBackedByUser: AgentsBackedByUserPayload;
  fetchOpportunity?: Maybe<FetchOpportunityPayload>;
  fetchPlatformDefaults?: Maybe<PlatformConfig>;
  fetchSourcePlatformsAggregated?: Maybe<SourcePlatformAggregatedPayload>;
  fetchUserBackups: Array<Maybe<UserBackupPayload>>;
  getAssigmentConfigs?: Maybe<AssigmentConfigs>;
  getAutoAssignableUsersList: Array<User>;
  getAutoheroCenters?: Maybe<Array<Maybe<SourceAccount>>>;
  getClosingReasons?: Maybe<GetClosingReasonsPayload>;
  getCurrentUserInfo?: Maybe<CurrentUserInfo>;
  getCustomerLeadAssignments?: Maybe<GetCustomerLeadAssignmentsPayload>;
  getCustomerLeadByEmail?: Maybe<CustomerLeadDto>;
  getCustomerLeadByPhone?: Maybe<CustomerLeadDto>;
  getCustomerLeadOverview?: Maybe<GetCustomerLeadOverviewPayload>;
  getCustomerLeadsByEmail?: Maybe<Array<Maybe<CustomerLeadDto>>>;
  getCustomerLeadsByPhone?: Maybe<Array<Maybe<CustomerLeadDto>>>;
  getEmailPlaceholders: Array<Maybe<EmailTemplatePlaceholdersGroup>>;
  /** email templates */
  getEmailTemplate: EmailTemplate;
  getFeatureStatus: FeatureStatus;
  getInteractionDocuments: Array<Maybe<InteractionDocument>>;
  getInteractions: Array<Maybe<Interaction>>;
  getMergeableCustomerLead?: Maybe<MergeableCustomerLeadPayload>;
  getMergedCustomerLeads?: Maybe<GetMergedCustomerLeadsPayload>;
  getOpportunityOverviewStatistic: OpportunityOverviewStatistics;
  /** @deprecated please use OpportunityOverview.opportunitiesCounts and OpportunityOverview.opportunitiesStatistics fields */
  getOpportunityStatistics: OpportunityStatistics;
  getOpportunityWithProductOverview: OpportunityWithProductOverview;
  getSelfAvailability?: Maybe<UserAvailabilityPeriods>;
  getSourcePlatformList?: Maybe<SourcePlatformListPayload>;
  getTaskAvailableSkippedReasons?: Maybe<Array<Maybe<TaskSkippedReason>>>;
  getTwilioPhoneNumbers: Array<TwilioPublicPhoneNumberPayload>;
  getTwilioPhoneNumbersWithProvider: Array<TwilioPublicPhoneNumberPayload>;
  getUserAvailability?: Maybe<UserAvailabilityPeriods>;
  getUserList: Array<User>;
  previewEmailTemplate: PreviewEmailTemplateResult;
  /** @deprecated please use searchProductByProductID query */
  searchCarByStockNumber?: Maybe<SearchCarResult>;
  searchEmailTemplates: SearchEmailTemplatePayload;
  searchProductByProductID?: Maybe<SearchCarResult>;
  searchRankedTasks?: Maybe<SearchRankedTaskResponse>;
  searchScheduledTasks?: Maybe<SearchScheduledTaskResponse>;
  searchTasks?: Maybe<SearchTaskResponse>;
  /** @deprecated please use getSourcePlatformList query */
  sourcePlatforms?: Maybe<SourcePlatformsPayload>;
  /** @deprecated please use getUserList query */
  users: Array<User>;
  validateOpportunity: OpportunitiesInfo;
}


export interface QueryCallSettingsArgs {
  input: CallSettingsInput;
}


export interface QueryCompiledEmailTemplateArgs {
  compiledEmailUUID: Scalars['ID'];
  opportunityUUID: Scalars['ID'];
}


export interface QueryEmailTemplatesArgs {
  market: Scalars['String'];
}


export interface QueryFetchAcAvailabilityScheduleArgs {
  branchId?: InputMaybe<Scalars['ID']>;
  week: Scalars['Int'];
  year?: InputMaybe<Scalars['Int']>;
}


export interface QueryFetchOpportunityArgs {
  input: FetchOpportunityInput;
}


export interface QueryFetchPlatformDefaultsArgs {
  platform: Scalars['String'];
}


export interface QueryFetchUserBackupsArgs {
  input: FetchUserBackupsInput;
}


export interface QueryGetClosingReasonsArgs {
  uuid?: InputMaybe<Scalars['String']>;
}


export interface QueryGetCustomerLeadAssignmentsArgs {
  uuid?: InputMaybe<Scalars['String']>;
}


export interface QueryGetCustomerLeadByEmailArgs {
  email?: InputMaybe<Scalars['String']>;
}


export interface QueryGetCustomerLeadByPhoneArgs {
  phone?: InputMaybe<Scalars['String']>;
}


export interface QueryGetCustomerLeadOverviewArgs {
  uuid?: InputMaybe<Scalars['String']>;
}


export interface QueryGetCustomerLeadsByEmailArgs {
  email?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
}


export interface QueryGetCustomerLeadsByPhoneArgs {
  phone?: InputMaybe<Scalars['String']>;
  platform?: InputMaybe<Scalars['String']>;
}


export interface QueryGetEmailPlaceholdersArgs {
  market: Scalars['String'];
}


export interface QueryGetEmailTemplateArgs {
  id: Scalars['ID'];
}


export interface QueryGetFeatureStatusArgs {
  filter: FeatureFilter;
}


export interface QueryGetInteractionDocumentsArgs {
  interactionUuid: Scalars['ID'];
}


export interface QueryGetInteractionsArgs {
  opportunityUuid: Scalars['String'];
}


export interface QueryGetMergeableCustomerLeadArgs {
  leadToMergeId?: InputMaybe<Scalars['String']>;
  leadUuid?: InputMaybe<Scalars['String']>;
}


export interface QueryGetMergedCustomerLeadsArgs {
  uuid?: InputMaybe<Scalars['String']>;
}


export interface QueryGetOpportunityOverviewStatisticArgs {
  filter?: InputMaybe<OpportunityOverviewStatisticsFilter>;
}


export interface QueryGetOpportunityWithProductOverviewArgs {
  filter: OpportunityOverviewFilter;
  first: Scalars['Int'];
  offset?: InputMaybe<Scalars['Int']>;
  order?: InputMaybe<OpportunitySortOrder>;
}


export interface QueryGetSelfAvailabilityArgs {
  branchId?: InputMaybe<Scalars['ID']>;
  week: Scalars['Int'];
  year?: InputMaybe<Scalars['Int']>;
}


export interface QueryGetTaskAvailableSkippedReasonsArgs {
  uuid: Scalars['ID'];
}


export interface QueryGetTwilioPhoneNumbersArgs {
  number?: InputMaybe<Scalars['String']>;
}


export interface QueryGetTwilioPhoneNumbersWithProviderArgs {
  number?: InputMaybe<Scalars['String']>;
  provider?: InputMaybe<Scalars['String']>;
}


export interface QueryGetUserAvailabilityArgs {
  branchId?: InputMaybe<Scalars['ID']>;
  userUuid: Scalars['ID'];
  week: Scalars['Int'];
  year?: InputMaybe<Scalars['Int']>;
}


export interface QueryGetUserListArgs {
  input?: InputMaybe<UserInput>;
}


export interface QueryPreviewEmailTemplateArgs {
  input: PreviewEmailTemplateInput;
}


export interface QuerySearchCarByStockNumberArgs {
  stockNumber?: InputMaybe<Scalars['String']>;
}


export interface QuerySearchEmailTemplatesArgs {
  filter: SearchEmailTemplatesFilter;
}


export interface QuerySearchProductByProductIdArgs {
  productId?: InputMaybe<Scalars['String']>;
}


export interface QuerySearchRankedTasksArgs {
  filter?: InputMaybe<RankedTaskFilter>;
}


export interface QuerySearchScheduledTasksArgs {
  filter?: InputMaybe<ScheduledTaskFilter>;
}


export interface QuerySearchTasksArgs {
  filter?: InputMaybe<TaskFilter>;
}


export interface QueryValidateOpportunityArgs {
  input: ValidateOpportunityInput;
}

export interface RankedTaskFilter {
  assignedTo: Array<Scalars['ID']>;
  closedFrom?: InputMaybe<Scalars['Date']>;
  closedTill?: InputMaybe<Scalars['Date']>;
  customerLeadUuid?: InputMaybe<Array<Scalars['ID']>>;
  market?: InputMaybe<Scalars['String']>;
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
  status: Scalars['String'];
}

export interface ReAssignCustomerLeadInput {
  customerLeadUuid: Scalars['ID'];
  userUuid?: InputMaybe<Scalars['ID']>;
}

export interface ReAssignCustomerLeadToMyselfInput {
  customerLeadUuid: Scalars['ID'];
}

export interface Reason {
  __typename?: 'Reason';
  label?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
}

export interface ReopenOpportunityInput {
  opportunityUuid: Scalars['ID'];
  reason: OpportunityReopeningReason;
}

export interface ReopenOpportunityPayload {
  __typename?: 'ReopenOpportunityPayload';
  opportunity: Opportunity;
}

export interface RescheduleTestDriveAppointmentInput {
  agentId: Scalars['ID'];
  appointmentId: Scalars['ID'];
  from: Scalars['LocalDateTime'];
  till: Scalars['LocalDateTime'];
}

export interface ResponseTaskCounters {
  __typename?: 'ResponseTaskCounters';
  done?: Maybe<Scalars['Int']>;
  overdue?: Maybe<Scalars['Int']>;
  skipped?: Maybe<Scalars['Int']>;
  toDo?: Maybe<Scalars['Int']>;
  toDoAll?: Maybe<Scalars['Int']>;
}

export interface ResponseTaskPageInfo {
  __typename?: 'ResponseTaskPageInfo';
  page?: Maybe<Scalars['Int']>;
  pageSize?: Maybe<Scalars['Int']>;
  totalCount?: Maybe<Scalars['Int']>;
  totalPageCount?: Maybe<Scalars['Int']>;
}

export enum Salutation {
  Miss = 'MISS',
  Mr = 'MR',
  Mrs = 'MRS',
  Ms = 'MS',
  Other = 'OTHER'
}

export interface ScheduledTaskFilter {
  assignedTo: Array<Scalars['ID']>;
  customerLeadUuid?: InputMaybe<Array<Scalars['ID']>>;
  fetchTaskPageInfo?: InputMaybe<FetchTaskPageInfo>;
  market?: InputMaybe<Scalars['String']>;
}

export interface SearchCarResult {
  __typename?: 'SearchCarResult';
  found: Scalars['Boolean'];
  product?: Maybe<CarProduct>;
}

export interface SearchEmailTemplatePayload {
  __typename?: 'SearchEmailTemplatePayload';
  emailTemplates: Array<Maybe<EmailTemplate>>;
  pageInfo: PageInfo;
}

export interface SearchEmailTemplatesFilter {
  market: Scalars['String'];
  sortingFilter: TemplatesSortingFilter;
}

export interface SearchRankedTaskResponse {
  __typename?: 'SearchRankedTaskResponse';
  counters?: Maybe<ResponseTaskCounters>;
  pageInfo?: Maybe<ResponseTaskPageInfo>;
  tasks: Array<Task>;
}

export interface SearchScheduledTaskResponse {
  __typename?: 'SearchScheduledTaskResponse';
  scheduledPage?: Maybe<TaskPage>;
}

export interface SearchTaskResponse {
  __typename?: 'SearchTaskResponse';
  counters?: Maybe<ResponseTaskCounters>;
  taskPages?: Maybe<TaskPages>;
}

export interface SecondaryWheels {
  __typename?: 'SecondaryWheels';
  active?: Maybe<Scalars['Boolean']>;
  grossPrice?: Maybe<Price>;
  wheelDetails?: Maybe<Array<Maybe<WheelDetail>>>;
}

export interface SendEmptyTemplateEmailInput {
  contactEmailUuid: Scalars['ID'];
  documentUuids?: InputMaybe<Array<Scalars['ID']>>;
  emailBody: Scalars['String'];
  emailSubject: Scalars['String'];
  language?: InputMaybe<Scalars['String']>;
  opportunityUuids?: InputMaybe<Array<Scalars['ID']>>;
}

export interface SendEmptyTemplateEmailPayload {
  __typename?: 'SendEmptyTemplateEmailPayload';
  interaction?: Maybe<Interaction>;
}

export interface SendTestEmailInput {
  emailBody: Scalars['String'];
  emailSubject: Scalars['String'];
}

export interface SetOpportunityProductInput {
  opportunityUuid: Scalars['ID'];
  stockNumber: Scalars['String'];
}

export interface SetOpportunityProductPayload {
  __typename?: 'SetOpportunityProductPayload';
  opportunity: Opportunity;
}

export interface ShowroomInfo {
  __typename?: 'ShowroomInfo';
  address?: Maybe<Scalars['String']>;
  available: Scalars['Boolean'];
  branchId: Scalars['Int'];
  city?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
}

export interface SkipTaskInput {
  skippedComment?: InputMaybe<Scalars['String']>;
  skippedReason: Scalars['String'];
  uuid: Scalars['ID'];
}

export interface Slot {
  __typename?: 'Slot';
  branchId: Scalars['ID'];
  from: Scalars['LocalDateTime'];
  till: Scalars['LocalDateTime'];
}

export enum SortingDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export interface SourceAccount {
  __typename?: 'SourceAccount';
  account: Scalars['String'];
  active?: Maybe<Scalars['Boolean']>;
  externalId?: Maybe<Scalars['String']>;
  isBranch?: Maybe<Scalars['Boolean']>;
  uuid?: Maybe<Scalars['ID']>;
  visible?: Maybe<Scalars['Boolean']>;
}

export interface SourceAccountPayload {
  __typename?: 'SourceAccountPayload';
  emails?: Maybe<Array<Maybe<Scalars['String']>>>;
  externalId?: Maybe<Scalars['String']>;
  externalId2?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  isBranch?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Maybe<Scalars['String']>>>;
  wkdaBranchId?: Maybe<Scalars['Long']>;
}

export interface SourceInput {
  id?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['ID']>;
}

export interface SourcePlatform {
  __typename?: 'SourcePlatform';
  accounts: Array<SourceAccount>;
  active?: Maybe<Scalars['Boolean']>;
  sourcePlatform: Scalars['String'];
}

export interface SourcePlatformAggregatedPayload {
  __typename?: 'SourcePlatformAggregatedPayload';
  sourcePlatforms?: Maybe<Array<Maybe<SourcePlatformPayload>>>;
}

export interface SourcePlatformListPayload {
  __typename?: 'SourcePlatformListPayload';
  sourcePlatforms: Array<SourcePlatform>;
}

export interface SourcePlatformPayload {
  __typename?: 'SourcePlatformPayload';
  id?: Maybe<Scalars['ID']>;
  isActive?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Maybe<Scalars['String']>>>;
  sourceAccounts?: Maybe<Array<Maybe<SourceAccountPayload>>>;
}

export interface SourcePlatformsPayload {
  __typename?: 'SourcePlatformsPayload';
  sourcePlatforms: Array<SourcePlatform>;
}

export interface StatisticsBucket {
  __typename?: 'StatisticsBucket';
  count: Scalars['Int'];
  key: Scalars['String'];
}

/** New Aggregate DTOs */
export interface StreetAddress {
  __typename?: 'StreetAddress';
  address?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
  zip?: Maybe<Scalars['String']>;
}

export interface StreetAddressInput {
  address?: InputMaybe<Scalars['String']>;
  city?: InputMaybe<Scalars['String']>;
  country: Scalars['String'];
  zip?: InputMaybe<Scalars['String']>;
}

export interface Subscription {
  __typename?: 'Subscription';
  test: CustomerLead;
}


export interface SubscriptionTestArgs {
  uuid?: InputMaybe<Scalars['String']>;
}

export interface Task {
  __typename?: 'Task';
  assignedTo?: Maybe<User>;
  closedAt?: Maybe<Scalars['Date']>;
  closedBy?: Maybe<User>;
  context?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  createdBy?: Maybe<User>;
  customerLead?: Maybe<CustomerLead>;
  eta?: Maybe<Scalars['Date']>;
  interactionChannel?: Maybe<Scalars['String']>;
  interactionCreated?: Maybe<Scalars['ID']>;
  interactionDone?: Maybe<Scalars['ID']>;
  onTime?: Maybe<Scalars['Boolean']>;
  opportunityId?: Maybe<Scalars['ID']>;
  opportunityPublicId?: Maybe<Scalars['String']>;
  resultChannel?: Maybe<Scalars['String']>;
  skippedComment?: Maybe<Scalars['String']>;
  skippedReason?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  targetMinutes?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  unattendedCallsCount?: Maybe<Scalars['Int']>;
  updatedAt?: Maybe<Scalars['Date']>;
  updatedBy?: Maybe<User>;
  uuid: Scalars['ID'];
}

export interface TaskFilter {
  assignedTo: Array<Scalars['ID']>;
  customerLeadUuid?: InputMaybe<Array<Scalars['ID']>>;
  market?: InputMaybe<Scalars['String']>;
  statusFilter: Array<TaskStatusFilter>;
}

export interface TaskPage {
  __typename?: 'TaskPage';
  pageInfo?: Maybe<ResponseTaskPageInfo>;
  tasks: Array<Task>;
}

export interface TaskPages {
  __typename?: 'TaskPages';
  donePage?: Maybe<TaskPage>;
  skippedPage?: Maybe<TaskPage>;
  toDoPage?: Maybe<TaskPage>;
}

export interface TaskSkippedReason {
  __typename?: 'TaskSkippedReason';
  commentNeeded?: Maybe<Scalars['Boolean']>;
  reason: Scalars['String'];
}

export interface TaskStatusFilter {
  closedFrom?: InputMaybe<Scalars['Date']>;
  closedTill?: InputMaybe<Scalars['Date']>;
  createdFrom?: InputMaybe<Scalars['Date']>;
  createdTill?: InputMaybe<Scalars['Date']>;
  fetchTaskPageInfo?: InputMaybe<FetchTaskPageInfo>;
  status: Scalars['String'];
}

export interface TemplatesPageInfo {
  page?: InputMaybe<Scalars['Int']>;
  pageSize?: InputMaybe<Scalars['Int']>;
}

export interface TemplatesSortingFilter {
  pageInfo: TemplatesPageInfo;
  sortByField?: InputMaybe<EmailTemplatesSortingField>;
  sortDirection?: InputMaybe<SortingDirection>;
}

export interface TestDriveAppointmentPayload {
  __typename?: 'TestDriveAppointmentPayload';
  agentId: Scalars['ID'];
  appointmentId: Scalars['ID'];
  comment?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  createdBy: Scalars['ID'];
  customerLeadId: Scalars['ID'];
  market: Scalars['String'];
  meetingOccurred?: Maybe<Scalars['Boolean']>;
  /** @deprecated Field is deprecated! Please use 'opportunityUuids' */
  opportunityId?: Maybe<Scalars['ID']>;
  opportunityUuids?: Maybe<Array<Maybe<Scalars['ID']>>>;
  slot: Slot;
  type: Scalars['String'];
}

export interface TradeIn {
  __typename?: 'TradeIn';
  carLead?: Maybe<CarLead>;
  createdOn?: Maybe<Scalars['Date']>;
  opportunityId?: Maybe<Scalars['String']>;
  opportunityUuid?: Maybe<Scalars['ID']>;
  state?: Maybe<Scalars['String']>;
  tradeInApplicable?: Maybe<Scalars['Boolean']>;
  uuid: Scalars['ID'];
}

export interface TwilioPublicPhoneNumberPayload {
  __typename?: 'TwilioPublicPhoneNumberPayload';
  number: Scalars['String'];
  retailPlatform: Scalars['String'];
  sourceAccountId?: Maybe<Scalars['ID']>;
  sourcePlatform: Scalars['String'];
}

export interface UnAssignCustomerLeadInput {
  customerLeadUuid: Scalars['ID'];
}

/** Post-refactoring DTOs */
export interface UpdateCustomerAggregateInput {
  customer: UpdateCustomerInput;
  uuid: Scalars['ID'];
}

export interface UpdateCustomerAggregatePayload {
  __typename?: 'UpdateCustomerAggregatePayload';
  address?: Maybe<StreetAddress>;
  customerLead: CustomerLead;
  email?: Maybe<EmailAddress>;
  phoneNumbers?: Maybe<Array<PhoneNumber>>;
}

export interface UpdateCustomerInput {
  address?: InputMaybe<StreetAddressInput>;
  email?: InputMaybe<EmailAddressInput>;
  personalData: UpdatePersonalDataInput;
  phoneNumbers?: InputMaybe<Array<PhoneNumberInput>>;
  preferredCommunicationChannel?: InputMaybe<CommunicationChannel>;
}

export interface UpdateCustomerLeadInput {
  customerLead: CustomerLeadInput;
}

export interface UpdateCustomerLeadNotesInput {
  customerLeadUuid: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
}

export interface UpdateCustomerLeadNotesPayload {
  __typename?: 'UpdateCustomerLeadNotesPayload';
  customerLead: CustomerLead;
}

export interface UpdateCustomerLeadPayload {
  __typename?: 'UpdateCustomerLeadPayload';
  customerLead: CustomerLead;
}

export interface UpdateOpportunityRatingInput {
  opportunityUuid: Scalars['String'];
  rating?: InputMaybe<OpportunityRating>;
}

export interface UpdateOpportunityRatingPayload {
  __typename?: 'UpdateOpportunityRatingPayload';
  opportunity?: Maybe<Opportunity>;
}

export interface UpdateOpportunityRelevanceInput {
  opportunityUuid: Scalars['String'];
  relevance?: InputMaybe<OpportunityRelevance>;
}

export interface UpdateOpportunityRelevancePayload {
  __typename?: 'UpdateOpportunityRelevancePayload';
  opportunity?: Maybe<Opportunity>;
}

export interface UpdatePersonalDataInput {
  companyName?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  salutation?: InputMaybe<Salutation>;
  type: CustomerLeadType;
}

export interface UpdateSourceAccountInput {
  emails?: InputMaybe<Array<Scalars['String']>>;
  externalId?: InputMaybe<Scalars['String']>;
  externalId2?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  isBranch?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phones?: InputMaybe<Array<Scalars['String']>>;
  wkdaBranchId?: InputMaybe<Scalars['Long']>;
}

export interface UpdateSourcePlatformInput {
  id: Scalars['ID'];
  isActive?: InputMaybe<Scalars['Boolean']>;
  name: Scalars['String'];
  phones?: InputMaybe<Array<Scalars['String']>>;
}

export interface UploadedDocumentPayload {
  __typename?: 'UploadedDocumentPayload';
  externalUrl: Scalars['String'];
  internalUrl: Scalars['String'];
  uuid: Scalars['ID'];
}

export interface User {
  __typename?: 'User';
  email: Scalars['String'];
  enabled?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  platforms: Array<UserPlatform>;
  uuid: Scalars['ID'];
}

export interface UserAvailabilityPeriod {
  __typename?: 'UserAvailabilityPeriod';
  availableHours: Array<Scalars['Int']>;
  dayOfWeek: Scalars['Int'];
  week: Scalars['Int'];
  year: Scalars['Int'];
}

export interface UserAvailabilityPeriodInput {
  availableHours: Array<Scalars['Int']>;
  dayOfWeek: Scalars['Int'];
  week: Scalars['Int'];
  year?: InputMaybe<Scalars['Int']>;
}

export interface UserAvailabilityPeriods {
  __typename?: 'UserAvailabilityPeriods';
  periods: Array<UserAvailabilityPeriod>;
  userId: Scalars['ID'];
}

export interface UserAvailabilityPeriodsInput {
  branchId?: InputMaybe<Scalars['ID']>;
  periods: Array<UserAvailabilityPeriodInput>;
  userId: Scalars['ID'];
}

export interface UserBackupInput {
  backupDate: Scalars['LocalDate'];
  backupUserId: Scalars['ID'];
  userId: Scalars['ID'];
}

export interface UserBackupPayload {
  __typename?: 'UserBackupPayload';
  backupDate: Scalars['LocalDate'];
  backupUser: User;
  id: Scalars['ID'];
  user: User;
}

export interface UserFlags {
  __typename?: 'UserFlags';
  canAccessToAllTask?: Maybe<Scalars['Boolean']>;
  canArchive?: Maybe<Scalars['Boolean']>;
  canBeAssigned?: Maybe<Scalars['Boolean']>;
  canClmConfigurationEdit?: Maybe<Scalars['Boolean']>;
  canClmConfigurationView?: Maybe<Scalars['Boolean']>;
  canEditPlatform?: Maybe<Scalars['Boolean']>;
  canEditTestDriveSchedule?: Maybe<Scalars['Boolean']>;
  canMergeLeads?: Maybe<Scalars['Boolean']>;
  canPeerAssign?: Maybe<Scalars['Boolean']>;
  canPeerReAssign?: Maybe<Scalars['Boolean']>;
  canReopen?: Maybe<Scalars['Boolean']>;
  canScheduleCallTeam?: Maybe<Scalars['Boolean']>;
  canSelfAssign?: Maybe<Scalars['Boolean']>;
  canSelfAvailabilityEdit?: Maybe<Scalars['Boolean']>;
  canSelfAvailabilityView?: Maybe<Scalars['Boolean']>;
  canSelfReAssign?: Maybe<Scalars['Boolean']>;
  canTeamAvailabilityEdit?: Maybe<Scalars['Boolean']>;
  canUpdateEmptyPlatform?: Maybe<Scalars['Boolean']>;
  canViewOnsiteAvailability?: Maybe<Scalars['Boolean']>;
  canViewTestDriveSchedule?: Maybe<Scalars['Boolean']>;
}

export interface UserInfo {
  __typename?: 'UserInfo';
  emailAddress?: Maybe<Scalars['String']>;
  enabled?: Maybe<Scalars['Boolean']>;
  firstName?: Maybe<Scalars['String']>;
  flags?: Maybe<UserFlags>;
  lastName?: Maybe<Scalars['String']>;
  telephonyProvider?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['ID']>;
}

export interface UserInput {
  enabled?: InputMaybe<Scalars['Boolean']>;
}

export interface UserPlatform {
  __typename?: 'UserPlatform';
  code: Scalars['String'];
}

export interface UserSchedule {
  __typename?: 'UserSchedule';
  appointments: Array<Appointment>;
  /** @deprecated Field is deprecated! Please use 'freeSlots' and 'appointments' */
  availableHours: Array<Scalars['Int']>;
  freeSlots: Array<Scalars['Int']>;
  user?: Maybe<User>;
}

export interface ValidateOpportunityInput {
  interactionChannel: Scalars['String'];
  interactionDateTime: Scalars['Date'];
  opportunityUuids: Array<InputMaybe<Scalars['ID']>>;
}

export interface Vehicle {
  __typename?: 'Vehicle';
  fuelType?: Maybe<Scalars['Int']>;
  gearType?: Maybe<Scalars['Int']>;
  image?: Maybe<Scalars['String']>;
  inShowroom?: Maybe<Scalars['Boolean']>;
  initialRegistration?: Maybe<Scalars['String']>;
  licensePlate?: Maybe<Scalars['String']>;
  mileage?: Maybe<Mileage>;
}

export interface VehicleMeta {
  __typename?: 'VehicleMeta';
  available?: Maybe<Scalars['Boolean']>;
  links?: Maybe<Array<Maybe<Link>>>;
}

export interface WarrantyOption {
  __typename?: 'WarrantyOption';
  level?: Maybe<Scalars['String']>;
  monthsCovered?: Maybe<Scalars['Int']>;
}

export interface WheelDetail {
  __typename?: 'WheelDetail';
  area?: Maybe<Scalars['String']>;
  profileDepth?: Maybe<Scalars['Int']>;
}

export type CreateExternalPhoneNumbersMutationVariables = Exact<{
  input: CreateExternalPhoneNumbersInput;
}>;


export type CreateExternalPhoneNumbersMutation = { __typename?: 'Mutation', createExternalPhoneNumbers?: Array<{ __typename?: 'ExternalPhoneNumberPayload', id?: string | null } | null> | null };

export type CreateSourceAccountMutationVariables = Exact<{
  input: CreateSourceAccountInput;
}>;


export type CreateSourceAccountMutation = { __typename?: 'Mutation', createSourceAccount?: { __typename?: 'SourceAccountPayload', id?: string | null } | null };

export type CreateSourcePlatformMutationVariables = Exact<{
  input: CreateSourcePlatformInput;
}>;


export type CreateSourcePlatformMutation = { __typename?: 'Mutation', createSourcePlatform?: { __typename?: 'SourcePlatformPayload', id?: string | null } | null };

export type UpdateSourceAccountMutationVariables = Exact<{
  input: UpdateSourceAccountInput;
}>;


export type UpdateSourceAccountMutation = { __typename?: 'Mutation', updateSourceAccount?: { __typename?: 'SourceAccountPayload', id?: string | null } | null };

export type UpdateSourcePlatformMutationVariables = Exact<{
  input: UpdateSourcePlatformInput;
}>;


export type UpdateSourcePlatformMutation = { __typename?: 'Mutation', updateSourcePlatform?: { __typename?: 'SourcePlatformPayload', id?: string | null } | null };

export type FetchSourcePlatformsAggregatedQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchSourcePlatformsAggregatedQuery = { __typename?: 'Query', fetchSourcePlatformsAggregated?: { __typename?: 'SourcePlatformAggregatedPayload', sourcePlatforms?: Array<{ __typename?: 'SourcePlatformPayload', id?: string | null, name?: string | null, isActive?: boolean | null, phones?: Array<string | null> | null, sourceAccounts?: Array<{ __typename?: 'SourceAccountPayload', id?: string | null, name?: string | null, externalId?: string | null, externalId2?: string | null, isActive?: boolean | null, isBranch?: boolean | null, phones?: Array<string | null> | null, emails?: Array<string | null> | null, wkdaBranchId?: number | null } | null> | null } | null> | null } | null };

export type GetCurrentUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserInfoQuery = { __typename?: 'Query', getCurrentUserInfo?: { __typename?: 'CurrentUserInfo', platforms: Array<string | null>, userInfo?: { __typename?: 'UserInfo', enabled?: boolean | null, firstName?: string | null, lastName?: string | null, uuid?: string | null, flags?: { __typename?: 'UserFlags', canViewOnsiteAvailability?: boolean | null, canViewTestDriveSchedule?: boolean | null, canEditTestDriveSchedule?: boolean | null, canClmConfigurationView?: boolean | null, canClmConfigurationEdit?: boolean | null } | null } | null } | null };


export const CreateExternalPhoneNumbersDocument = gql`
    mutation CreateExternalPhoneNumbers($input: CreateExternalPhoneNumbersInput!) {
  createExternalPhoneNumbers(input: $input) {
    id
  }
}
    `;
export type CreateExternalPhoneNumbersMutationFn = Apollo.MutationFunction<CreateExternalPhoneNumbersMutation, CreateExternalPhoneNumbersMutationVariables>;

/**
 * __useCreateExternalPhoneNumbersMutation__
 *
 * To run a mutation, you first call `useCreateExternalPhoneNumbersMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateExternalPhoneNumbersMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createExternalPhoneNumbersMutation, { data, loading, error }] = useCreateExternalPhoneNumbersMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateExternalPhoneNumbersMutation(baseOptions?: Apollo.MutationHookOptions<CreateExternalPhoneNumbersMutation, CreateExternalPhoneNumbersMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateExternalPhoneNumbersMutation, CreateExternalPhoneNumbersMutationVariables>(CreateExternalPhoneNumbersDocument, options);
      }
export type CreateExternalPhoneNumbersMutationHookResult = ReturnType<typeof useCreateExternalPhoneNumbersMutation>;
export type CreateExternalPhoneNumbersMutationResult = Apollo.MutationResult<CreateExternalPhoneNumbersMutation>;
export type CreateExternalPhoneNumbersMutationOptions = Apollo.BaseMutationOptions<CreateExternalPhoneNumbersMutation, CreateExternalPhoneNumbersMutationVariables>;
export const CreateSourceAccountDocument = gql`
    mutation CreateSourceAccount($input: CreateSourceAccountInput!) {
  createSourceAccount(input: $input) {
    id
  }
}
    `;
export type CreateSourceAccountMutationFn = Apollo.MutationFunction<CreateSourceAccountMutation, CreateSourceAccountMutationVariables>;

/**
 * __useCreateSourceAccountMutation__
 *
 * To run a mutation, you first call `useCreateSourceAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSourceAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSourceAccountMutation, { data, loading, error }] = useCreateSourceAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSourceAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateSourceAccountMutation, CreateSourceAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSourceAccountMutation, CreateSourceAccountMutationVariables>(CreateSourceAccountDocument, options);
      }
export type CreateSourceAccountMutationHookResult = ReturnType<typeof useCreateSourceAccountMutation>;
export type CreateSourceAccountMutationResult = Apollo.MutationResult<CreateSourceAccountMutation>;
export type CreateSourceAccountMutationOptions = Apollo.BaseMutationOptions<CreateSourceAccountMutation, CreateSourceAccountMutationVariables>;
export const CreateSourcePlatformDocument = gql`
    mutation CreateSourcePlatform($input: CreateSourcePlatformInput!) {
  createSourcePlatform(input: $input) {
    id
  }
}
    `;
export type CreateSourcePlatformMutationFn = Apollo.MutationFunction<CreateSourcePlatformMutation, CreateSourcePlatformMutationVariables>;

/**
 * __useCreateSourcePlatformMutation__
 *
 * To run a mutation, you first call `useCreateSourcePlatformMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSourcePlatformMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSourcePlatformMutation, { data, loading, error }] = useCreateSourcePlatformMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSourcePlatformMutation(baseOptions?: Apollo.MutationHookOptions<CreateSourcePlatformMutation, CreateSourcePlatformMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSourcePlatformMutation, CreateSourcePlatformMutationVariables>(CreateSourcePlatformDocument, options);
      }
export type CreateSourcePlatformMutationHookResult = ReturnType<typeof useCreateSourcePlatformMutation>;
export type CreateSourcePlatformMutationResult = Apollo.MutationResult<CreateSourcePlatformMutation>;
export type CreateSourcePlatformMutationOptions = Apollo.BaseMutationOptions<CreateSourcePlatformMutation, CreateSourcePlatformMutationVariables>;
export const UpdateSourceAccountDocument = gql`
    mutation UpdateSourceAccount($input: UpdateSourceAccountInput!) {
  updateSourceAccount(input: $input) {
    id
  }
}
    `;
export type UpdateSourceAccountMutationFn = Apollo.MutationFunction<UpdateSourceAccountMutation, UpdateSourceAccountMutationVariables>;

/**
 * __useUpdateSourceAccountMutation__
 *
 * To run a mutation, you first call `useUpdateSourceAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSourceAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSourceAccountMutation, { data, loading, error }] = useUpdateSourceAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSourceAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSourceAccountMutation, UpdateSourceAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSourceAccountMutation, UpdateSourceAccountMutationVariables>(UpdateSourceAccountDocument, options);
      }
export type UpdateSourceAccountMutationHookResult = ReturnType<typeof useUpdateSourceAccountMutation>;
export type UpdateSourceAccountMutationResult = Apollo.MutationResult<UpdateSourceAccountMutation>;
export type UpdateSourceAccountMutationOptions = Apollo.BaseMutationOptions<UpdateSourceAccountMutation, UpdateSourceAccountMutationVariables>;
export const UpdateSourcePlatformDocument = gql`
    mutation UpdateSourcePlatform($input: UpdateSourcePlatformInput!) {
  updateSourcePlatform(input: $input) {
    id
  }
}
    `;
export type UpdateSourcePlatformMutationFn = Apollo.MutationFunction<UpdateSourcePlatformMutation, UpdateSourcePlatformMutationVariables>;

/**
 * __useUpdateSourcePlatformMutation__
 *
 * To run a mutation, you first call `useUpdateSourcePlatformMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSourcePlatformMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSourcePlatformMutation, { data, loading, error }] = useUpdateSourcePlatformMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSourcePlatformMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSourcePlatformMutation, UpdateSourcePlatformMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSourcePlatformMutation, UpdateSourcePlatformMutationVariables>(UpdateSourcePlatformDocument, options);
      }
export type UpdateSourcePlatformMutationHookResult = ReturnType<typeof useUpdateSourcePlatformMutation>;
export type UpdateSourcePlatformMutationResult = Apollo.MutationResult<UpdateSourcePlatformMutation>;
export type UpdateSourcePlatformMutationOptions = Apollo.BaseMutationOptions<UpdateSourcePlatformMutation, UpdateSourcePlatformMutationVariables>;
export const FetchSourcePlatformsAggregatedDocument = gql`
    query FetchSourcePlatformsAggregated {
  fetchSourcePlatformsAggregated {
    sourcePlatforms {
      id
      name
      isActive
      phones
      sourceAccounts {
        id
        name
        externalId
        externalId2
        isActive
        isBranch
        phones
        emails
        wkdaBranchId
      }
    }
  }
}
    `;

/**
 * __useFetchSourcePlatformsAggregatedQuery__
 *
 * To run a query within a React component, call `useFetchSourcePlatformsAggregatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSourcePlatformsAggregatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSourcePlatformsAggregatedQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchSourcePlatformsAggregatedQuery(baseOptions?: Apollo.QueryHookOptions<FetchSourcePlatformsAggregatedQuery, FetchSourcePlatformsAggregatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSourcePlatformsAggregatedQuery, FetchSourcePlatformsAggregatedQueryVariables>(FetchSourcePlatformsAggregatedDocument, options);
      }
export function useFetchSourcePlatformsAggregatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSourcePlatformsAggregatedQuery, FetchSourcePlatformsAggregatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSourcePlatformsAggregatedQuery, FetchSourcePlatformsAggregatedQueryVariables>(FetchSourcePlatformsAggregatedDocument, options);
        }
export type FetchSourcePlatformsAggregatedQueryHookResult = ReturnType<typeof useFetchSourcePlatformsAggregatedQuery>;
export type FetchSourcePlatformsAggregatedLazyQueryHookResult = ReturnType<typeof useFetchSourcePlatformsAggregatedLazyQuery>;
export type FetchSourcePlatformsAggregatedQueryResult = Apollo.QueryResult<FetchSourcePlatformsAggregatedQuery, FetchSourcePlatformsAggregatedQueryVariables>;
export const GetCurrentUserInfoDocument = gql`
    query GetCurrentUserInfo {
  getCurrentUserInfo {
    platforms
    userInfo {
      enabled
      firstName
      lastName
      uuid
      flags {
        canViewOnsiteAvailability
        canViewTestDriveSchedule
        canEditTestDriveSchedule
        canClmConfigurationView
        canClmConfigurationEdit
      }
    }
  }
}
    `;

/**
 * __useGetCurrentUserInfoQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserInfoQuery, GetCurrentUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserInfoQuery, GetCurrentUserInfoQueryVariables>(GetCurrentUserInfoDocument, options);
      }
export function useGetCurrentUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserInfoQuery, GetCurrentUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserInfoQuery, GetCurrentUserInfoQueryVariables>(GetCurrentUserInfoDocument, options);
        }
export type GetCurrentUserInfoQueryHookResult = ReturnType<typeof useGetCurrentUserInfoQuery>;
export type GetCurrentUserInfoLazyQueryHookResult = ReturnType<typeof useGetCurrentUserInfoLazyQuery>;
export type GetCurrentUserInfoQueryResult = Apollo.QueryResult<GetCurrentUserInfoQuery, GetCurrentUserInfoQueryVariables>;